import React, {FC, Fragment, ReactNode} from "react";
import {TranslationsType} from "../../types/homePageTypes";
import {ICurrencies} from "../ForCWV/Wrappers/MainLayout";
import cls from "./builder.module.scss"
import Heading from "./Heading";
import HyperLink from "./HyperLink";
import Editor from "./Editor";
import Accordion from "./Accordion";
import ImageUpload from "./ImageUpload";
import SliderComponent from "./SliderComponent";
import Button from "./Button";
import Video from "./Video";
import PostsComponent from "./PostsComponent";
import FormBuilderComponent from "./FormBuilderComponent";
import Carousel from "./CarouselComponent";
import ProductList from "./ProductList";
import CountUpComponent from "./CountUp";
import MasonryComponent from "./Masonry";
import DropList from "./DropList";
import IconComponent from "./Icon";
import MapComponent from "./Map";

interface IRenderComponent {
    data: any
    dbName: string
    translationsData: TranslationsType,
    selectedLocale: string,
    backOrderValue: string,
    currencies: Array<ICurrencies>;
    selectedCurrItems: {
        selectedCurrCode: string;
        selectedCurrRate: string;
        selectedCurrSymbol: string
    };
    isMobile: boolean,
    recaptchaActive: string;
    lcpElement?: boolean;
}

const RenderBuilderComponent: FC<IRenderComponent> = (
    {
        data,
        dbName,
        translationsData,
        selectedLocale,
        selectedCurrItems,
        currencies,
        backOrderValue,
        isMobile,
        recaptchaActive,
        lcpElement
    }
) => {
    const VideoComponentLayout = ({children}: { children: ReactNode }) => {
        const videoToggle = {
            "true": <div className={cls.video_wrapper}>{children}</div>,
            "false": <Fragment>{children}</Fragment>,
        }
        return videoToggle[`${!!data?.fullScreen}`]
    }
    const builderComponents: Record<string, React.JSX.Element> = {
        Heading: <Heading item={data}/>,
        HyperLink: <HyperLink item={data}/>,
        Editor: <Editor item={data}/>,
        Accordion: <Accordion item={data}/>,
        DropList: <DropList item={data} />,
        ImageUpload: <ImageUpload item={data} dbName={dbName} lcpElement={lcpElement} isMobile={isMobile}/>,
        Slider: <SliderComponent item={data}/>,
        Button: <Button item={data} selectedLocale={selectedLocale} currencies={currencies} backOrderValue={backOrderValue}/>,
        Video: <VideoComponentLayout><Video item={data}/></VideoComponentLayout>,
        Posts: <PostsComponent item={data} translationsData={translationsData}/>,
        FormBuilder: <FormBuilderComponent item={data} recaptchaActive={recaptchaActive}/>,
        ImageBox: <Carousel item={data}/>,
        Testimonials: <Carousel item={data}/>,
        BrandList: <Carousel item={data}/>,
        ProductList: <ProductList
            item={data}
            dbName={dbName}
            translationsData={translationsData}
            backOrderValue={backOrderValue}
            selectedCurrItems={selectedCurrItems}
            selectedLocale={selectedLocale}
            isMobile={isMobile}
        />,
        CountUp: <CountUpComponent item={data}/>,
        Masonry: <MasonryComponent item={data}/>,
        Icon: <IconComponent item={data}/>,
        Map: <MapComponent item={data}/>,
        Grid: <></>,
    }
    return builderComponents[data?.content as string] || <></>
}

export default RenderBuilderComponent;
